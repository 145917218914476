<template>
  <div class="carlist">
    <!-- 面包屑 -->
    <div class="crumb">
      <Breadcrumb separator=">">
        <Breadcrumb-item :to="{ path: '/' }">悦心车</Breadcrumb-item>
        <Breadcrumb-item :to="{ path: '/carlist' }">车辆列表</Breadcrumb-item>
      </Breadcrumb>
    </div>
    <!-- 选择框BIG -->
    <div class="option-box">
      <div class="option-brand">
        <div class="left">品牌:</div>
        <div class="right">
          <div class="brands-wraper" ref="brands">
            <div
              class="brand brand-index"
              v-for="(item, index) in brands"
              :key="index"
            >
              <div
                v-if="index < num"
                class="brand-list"
                :class="item.id == brand_id ? 'hot' : ''"
                @click="chooseBrand(item, index)"
              >
                {{ item.name }}
              </div>
            </div>
            <div @click="AllBrands" class="allbrands">
              {{ cue }}
              <span :class="flag == false ? 'hot' : ''">
                <img src="@/static/bottom.png" alt />
              </span>
            </div>
          </div>
          <!-- <div class="all" @click="AllBrands">
            <div class="all-left">{{ cue }}</div>
            <div class="right">
              <img src="" alt="" />
            </div>
          </div>-->
        </div>
      </div>

      <div class="option-price">
        <div class="left">价格:</div>
        <div class="right">
          <div class="price-wraper">
            <div
              class="price-list"
              :class="item.value == sale_price ? 'hot' : ''"
              v-for="(item, index) in price"
              :key="index"
              @click="choosePrice(item, index)"
            >
              {{ item.label }}
            </div>
          </div>
        </div>
      </div>

      <div class="option-type">
        <div class="left">车型:</div>
        <div class="right">
          <div class="price-wraper">
            <div
              class="price-list"
              :class="item.value == spec_id ? 'hot' : ''"
              v-for="(item, index) in specs"
              :key="index"
              @click="chooseSpecs(item, index)"
            >
              {{ item.label }}
            </div>
          </div>
        </div>
      </div>

      <div class="option-discharge">
        <div class="left">排放标准:</div>
        <div class="right">
          <div class="price-wraper">
            <div
              class="price-list"
              :class="emissionParams == item.value ? 'hot' : ''"
              v-for="(item, index) in emission"
              :key="index"
              @click="chooseEmission(item, index)"
            >
              {{ item.label }}
            </div>
          </div>
        </div>
      </div>

      <div class="selected">
        <div class="left">已选:</div>
        <div class="right">
          <div class="price-wraper">
            <Tag
              v-for="(item, index) in selected"
              :key="index"
              closable
              @on-close="closeSelected(index)"
              v-show="item != '不限'"
              >{{ item }}</Tag
            >
          </div>
        </div>
      </div>
    </div>

    <!-- 车辆列表 -->
    <div class="cars-wraper">
      <div class="container">
        <!-- <p class="title">
          <i class="line"></i>
          <span class="text">性价比推荐</span>
          <i class="line"></i>
        </p>-->
        <div class="card-list">
          <div
            class="card-item"
            v-for="(item, index) in carlist"
            :key="index"
            @click="goDetail(item)"
          >
            <div
              class="pic"
              :style="[
                {
                  backgroundImage: 'url(' + baseurl + item.car_pic + ')',
                },
              ]"
            >
              <!-- <img :src="baseurl + item.car_pic" alt="" /> -->
            </div>
            <el-tooltip
              class="item"
              effect="dark"
              :content="item.car_name"
              placement="top-start"
            >
              <p class="card-name">{{ item.car_name }}</p>
            </el-tooltip>
            <p class="card-time">{{ item.travel_km }} | {{ item.car_age }}</p>
            <p class="price">
              <span class="proce-unit">￥</span>
              {{ item.sale_price }}万
              <span class="old-price">新车含税价{{ item.price }}万</span>
            </p>
          </div>
        </div>
        <!-- <div class="see-more" @click="$router.push({path:'/carlist'})">
          查看更多
          <i class="see-more-icon"></i>
        </div>-->
      </div>
      <div class="cars-page" v-if="carlist && carlist.length > 0">
        <el-pagination
          background
          layout="prev, pager, next"
          @prev-click="prevPage"
          @next-click="nextPage"
          @current-change="currentPage"
          :total="pagetotal"
        ></el-pagination>
      </div>
    </div>

    <!-- 找不到车 -->
    <div class="notfind">
      <div class="notfind-title">没有您心仪的车型？</div>
      <div class="btn" @click="toCustom">点击此处开启定制</div>
    </div>
  </div>
</template>

<script>
import { getPcFilterField, pcCarList } from "@/api/index.js";

export default {
  data() {
    return {
      baseurl: "https://www.yuexinche.com",
      num: 0,
      // 品牌
      brandnow: { id: "" },
      brands: [],
      // 价格
      pricenow: { sale_price: "" },
      price: [],
      // 车型
      specsnow: { spec_id: "" },
      specs: [],
      // 排量
      emissionenow: { emissionParams: "" },
      emission: [],
      selectednow: 0,
      selected: ["不限", "不限", "不限", "不限"],
      cue: "全部",
      flag: 1,
      submitInfo: { tel: "", price: "", type: "", kl: "", year: "", city: "" },
      // 搜索条件
      brand_id: "",
      sale_price: "",
      spec_id: "",
      emissionParams: "",
      page: 1,
      pagetotal: 1,
      carlist: [],
    };
  },
  created() {
    // 拿到查询参数  没有和不限则为0
    this.brand_id = this.$route.query.brand_id
      ? this.$route.query.brand_id
      : "0";
    this.sale_price = this.$route.query.sale_price
      ? this.$route.query.sale_price
      : "0";
    this.spec_id =
      this.$route.query.spec_id && this.$route.query.spec_id != "不限"
        ? this.$route.query.spec_id
        : "0";
    this.emissionParams = this.$route.query.emission
      ? this.$route.query.emission
      : "0";
    console.log(
      this.brand_id,
      this.sale_price,
      this.spec_id,
      this.emissionParams
    );
  },
  async mounted() {
    // 品牌与车型
    await this.getPcFilterField();
    // 获取默认排列顺序
    await this.initNum();
    // 车辆列表
    this.pcCarList();
  },
  methods: {
    // 去悦心定制页
    toCustom() {
      this.$router.push({
        path:'/custom'
      })
    },
    //去车辆列表页
    goDetail(item) {
      this.$router.push({
        path: "/detail",
        query: { id: item.id },
      });
      this.$store.commit("getRecordCars", item);
    },
    // 车辆列表
    pcCarList() {
      pcCarList({
        p: this.page,
        brand_id: this.brand_id,
        sale_price: this.sale_price,
        spec_id: this.spec_id,
        emission: this.emissionParams,
      }).then((res) => {
        this.carlist = res.data;
        this.pagetotal = res.page_total * 10;
      });
    },
    // 品牌与车型
    getPcFilterField() {
      return getPcFilterField({}).then((res) => {
        this.brands = res.data.brands;
        this.brands.unshift({ id: "0", name: "不限", pic: "" });
        this.selected[0] = this.brands.filter(
          //过滤为了上已选内容高亮
          (item) => item.id == this.brand_id
        )[0].name;
        this.num = this.brands.length;

        this.price = res.data.price;
        this.price.unshift({ label: "不限", value: "0" });
        this.selected[1] = this.price.filter(
          (
            item //过滤为了上已选内容高亮
          ) => item.value == this.sale_price
        )[0].label;

        this.specs = res.data.specs;
        this.specs.unshift({ label: "不限", value: "0" });
        this.specs.pop();
        this.selected[2] = this.specs.filter(
          (
            item //过滤为了上已选内容高亮
          ) => item.value == this.spec_id
        )[0].label;

        this.emission = res.data.emission;
        this.emission.unshift({ label: "不限", value: "0" });
        this.selected[3] = this.emission.filter(
          (
            item //过滤为了上已选内容高亮
          ) => item.value == this.emissionParams
        )[0].label;
      });
    },
    // 选择品牌
    chooseBrand(item) {
      this.page = 1;
      this.brand_id = item.id;
      this.brandnow = item;
      this.selected[0] = item.name;
      // 车辆列表
      this.pcCarList();
    },
    // 选择价格
    choosePrice(item) {
      this.page = 1;
      this.sale_price = item.value;
      this.pricenow = item;
      this.selected[1] = item.label;
      // 车辆列表
      this.pcCarList();
    },
    // 选择类型
    chooseSpecs(item) {
      this.page = 1;
      this.specsnow = item;
      this.spec_id = item.value;
      this.selected[2] = item.label;
      // 车辆列表
      this.pcCarList();
    },
    // 选择排量
    chooseEmission(item) {
      this.page = 1;
      this.emissionenow = item;
      this.emissionParams = item.value;
      this.selected[3] = item.label;
      // 车辆列表
      this.pcCarList();
    },
    // 关闭选择
    closeSelected(index) {
      this.page = 1;
      if (index == 0) {
        this.brand_id = 0;
        this.selected[0] = "不限";
      } else if (index == 1) {
        this.sale_price = 0;
        this.selected[1] = "不限";
      } else if (index == 2) {
        this.spec_id = 0;
        this.selected[2] = "不限";
      } else if (index == 3) {
        this.emissionParams = 0;
        this.selected[3] = "不限";
      }
      // 车辆列表
      this.pcCarList();
    },
    // 全部品牌展开收起
    AllBrands() {
      this.flag = !this.flag;
      if (this.flag == 0) {
        this.cue = "收起";
        this.num = this.brands.length;
        this.$refs.brands.style.height = "auto";
      } else {
        this.cue = "全部";
        this.num = 11;
        this.$refs.brands.style.height = "50px";
      }
      // this.initNum();
    },

    // 获取初始数量
    initNum() {
      let w = this.$refs.brands.clientWidth;
      let divs = document.getElementsByClassName("brand-index");
      let ds = 0;
      let n = 0;
      for (let i = 0; i < divs.length; i++) {
        var lastW = divs[i].clientWidth;
        if (ds < w) {
          console.log(ds);
          ds += lastW;
          n = i + 1;
        }
      }
      this.num = n;
    },
    // 当前页改变
    currentPage(e) {
      this.page = e;
      // 车辆列表
      this.pcCarList();
    },
    // 上一页
    prevPage(e) {
      this.page = e;
      // 车辆列表
      this.pcCarList();
    },
    // 下一页
    nextPage(e) {
      this.page = e;
      // 车辆列表
      this.pcCarList();
    },
  },
};
</script>

<style lang="less" scoped>
.carlist {
  width: 100%;
  width: 1200px;
  margin: 0 auto;

  .crumb {
    width: 100%;
    height: 20px;
    margin-top: 50px;
    /deep/ .ivu-breadcrumb {
      color: #333;
      font-weight: 500;
      .ivu-breadcrumb-item-separator {
        color: #333;
        font-weight: 500;
      }
    }

    .ivu-breadcrumb > span:last-child {
      color: #333;
      font-weight: 500;
    }
  }
  // 选择框
  .option-box {
    margin-top: 25px;
    border: 1px solid rgba(221, 221, 221, 1);

    .option-brand {
      width: 100%;
      display: flex;
      .left {
        width: 70px;
        height: auto;
        text-align: center;
        line-height: 50px;
        color: #666;
        font-size: 14px;
        background-color: #f5f9fc;
      }
      .right {
        height: auto;
        width: calc(100% - 70px);
        position: relative;
        .brands-wraper {
          width: 100%;
          height: 50px;
          display: flex;
          overflow: hidden;
          flex-wrap: wrap;
          font-size: 14px;
          color: #333;
          padding: 0 5px;
          box-sizing: border-box;
          // padding-right: 110px;
          .brand {
            width: auto;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            .brand-list {
              width: auto;
              height: 24px;
              line-height: 24px;
              padding: 0 20px;
              box-sizing: border-box;
              text-align: center;
              margin-right: 20px;
              cursor: pointer;
              &:hover {
                background-color: #0097e2;
                color: #fff;
              }
            }
            .hot {
              background-color: #0097e2;
              color: #fff;
            }
          }
          .allbrands {
            align-items: center;
            display: flex;
            justify-content: center;
            color: #666;
            span {
              width: 15px;
              height: 9px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-left: 5px;
              img {
                width: 100%;
              }
            }
            .hot {
              transform: rotate(180deg);
            }
          }
        }
        .all {
          position: absolute;
          top: 0;
          right: 0;
          width: 120px;
          height: 50px;
          line-height: 50px;
          display: flex;
          align-items: center;
          font-size: 14px;
          color: #666;
          justify-content: center;
          background-color: #fff;
          .all-left {
            width: 50%;
            height: 100%;
            text-align: center;
          }
          .right {
            width: 15px;
            height: 10px;
            background-color: red;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              max-width: 100%;
              max-height: 100%;
            }
          }
          // opacity: 0.3;
        }
      }
    }

    .option-price {
      width: 100%;
      display: flex;
      .left {
        width: 70px;
        height: auto;
        text-align: center;
        line-height: 50px;
        color: #666;
        font-size: 14px;
        background-color: #f5f9fc;
      }
      .right {
        width: calc(100% - 70px);
        .price-wraper {
          width: 100%;
          height: 50px;
          display: flex;
          align-items: center;
          padding: 0 5px;
          box-sizing: border-box;
          border-top: 1px solid #ddd;
          .price-list {
            font-size: 14px;
            color: #333;
            padding: 0 20px;
            box-sizing: border-box;
            text-align: center;
            height: 24px;
            line-height: 24px;
            margin-right: 20px;
            cursor: pointer;
            &:hover {
              background-color: #0097e2;
              color: #fff;
            }
          }
          .hot {
            background-color: #0097e2;
            color: #fff;
          }
        }
      }
    }

    .option-type {
      width: 100%;
      display: flex;
      .left {
        width: 70px;
        height: auto;
        text-align: center;
        line-height: 50px;
        color: #666;
        font-size: 14px;
        background-color: #f5f9fc;
      }
      .right {
        width: calc(100% - 70px);
        .price-wraper {
          width: 100%;
          height: 50px;
          display: flex;
          align-items: center;
          padding: 0 5px;
          box-sizing: border-box;
          border-top: 1px solid #ddd;
          .price-list {
            font-size: 14px;
            color: #333;
            padding: 0 20px;
            box-sizing: border-box;
            text-align: center;
            margin-right: 20px;
            height: 24px;
            line-height: 24px;
            cursor: pointer;
            &:hover {
              background-color: #0097e2;
              color: #fff;
            }
          }
          .hot {
            background-color: #0097e2;
            color: #fff;
          }
        }
      }
    }

    .option-discharge {
      width: 100%;
      display: flex;
      .left {
        width: 70px;
        height: auto;
        text-align: center;
        line-height: 50px;
        color: #666;
        font-size: 14px;
        background-color: #f5f9fc;
      }
      .right {
        width: calc(100% - 70px);
        .price-wraper {
          width: 100%;
          height: 50px;
          display: flex;
          align-items: center;
          padding: 0 5px;
          box-sizing: border-box;
          border-top: 1px solid #ddd;
          .price-list {
            font-size: 14px;
            color: #333;
            padding: 0 20px;
            box-sizing: border-box;
            text-align: center;
            margin-right: 20px;
            height: 24px;
            line-height: 24px;
            cursor: pointer;
            &:hover {
              background-color: #0097e2;
              color: #fff;
            }
          }
          .hot {
            background-color: #0097e2;
            color: #fff;
          }
        }
      }
    }

    .selected {
      width: 100%;
      display: flex;
      .left {
        width: 70px;
        height: auto;
        text-align: center;
        line-height: 50px;
        color: #666;
        font-size: 14px;
        background-color: #f5f9fc;
      }
      .right {
        width: calc(100% - 70px);
        .price-wraper {
          width: 100%;
          height: 50px;
          display: flex;
          align-items: center;
          padding: 0 20px;
          box-sizing: border-box;
          border-top: 1px solid #ddd;
          font-size: 14px;
          color: #333;
        }
      }
    }
  }

  // 车辆
  .cars-wraper {
    width: 100%;
    .container {
      // margin-top: 102px;
      .title {
        display: flex;
        align-items: center;
        justify-content: center;
        .line {
          display: inline-block;
          width: 93px;
          height: 1px;
          background: #000000;
          opacity: 0.1;
        }
        .text {
          color: #0097e2;
          font-size: 30px;
          font-weight: 500;
          margin: 0 19px;
        }
      }
      .card-list {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        .card-item {
          width: 286px;
          background: rgba(255, 255, 255, 1);
          border: 1px solid rgba(244, 244, 244, 1);
          margin-right: 17px;
          margin-top: 33px;
          cursor: pointer;
          &:nth-child(4n) {
            margin-right: 0;
          }
          .pic {
            width: 100%;
            height: 203px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
            img {
              max-width: 100%;
              max-height: 100%;
            }
          }
          .card-name {
            font-size: 16px;
            font-weight: 550;
            color: #000000;
            margin-left: 10px;
            margin-top: 13px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
          .card-time {
            font-size: 16px;
            font-weight: 300;
            color: #000;
            margin-left: 10px;
            margin-top: 4px;
            margin-bottom: 13px;
          }
          .price {
            font-size: 20px;
            padding-left: 10px;
            color: #207fcd;
            // background: #0097e2;
            font-weight: bold;
            .proce-unit {
              font-size: 20px;
              color: #207fcd;
              font-weight: bold;
            }
            .old-price {
              font-size: 14px;
              font-weight: 300;
              // text-decoration: line-through;
              color: #919191;
              margin-left: 12px;
            }
          }
        }
      }
      .see-more {
        width: 190px;
        height: 42px;
        background: rgba(0, 151, 226, 1);
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 21px;
        font-weight: 400;
        color: #fff;
        margin: 58px auto 127px;
        cursor: pointer;
      }
    }
    .cars-page {
      width: 100%;
      margin: 50px 0;
      .el-pagination {
        margin: 0 auto;
        width: fit-content;
      }
    }
  }

  // 找不到车
  .notfind {
    width: 100%;
    background-color: #f5f9fc;
    padding: 45px 40px;
    box-sizing: border-box;
    margin: 40px 0;
    .notfind-title {
      font-size: 24px;
      color: #919191;
      text-align: center;
      width: 100%;
    }
    .btn {
      width: 216px;
      height: 38px;
      border: 2px solid #207fcd;
      border-radius: 5px;
      font-size: 18px;
      line-height: 38px;
      color: #207fcd;
      text-align: center;
      margin: 20px auto 0;
      cursor: pointer;
      &:hover{
        background-color: rgb(99, 95, 95);
        color: #fff;
        border:none
      }
    }
  }
}
</style>
